import { render, staticRenderFns } from "./PokémonItem.vue?vue&type=template&id=21329212&scoped=true&"
import script from "./PokémonItem.vue?vue&type=script&lang=js&"
export * from "./PokémonItem.vue?vue&type=script&lang=js&"
import style0 from "./PokémonItem.vue?vue&type=style&index=0&id=21329212&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21329212",
  null
  
)

export default component.exports